/**
Extra CSS customizations on top of bootstrap
*/

.title {
  text-align: center;
  font-family: arial, sans-serif;
}

.active {
  color: black;
}

.data {
  font-family: Helvetica -apple-system,'Helvetica Neue', sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.MuiTableSortLabel-icon {
  opacity: 1;
}

.data td, .data th {
  padding: 8px;
}

.data tr:nth-child(even){background-color: #f2f2f2;}

.data tr:hover {background-color: #ddd;}

.data th {
  padding-top: 12px;
  padding-bottom: 12px;
  color: white;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.sticky-header th {
   position: sticky; top: 0;
}

table {
  overflow-x: auto;
}

.table-fixed {
  table-layout: fixed;
}
.table-display table {
  display: block;
}

td {
  text-align: left;
}

.padding {
  padding: 20px;
}
